<template>
  <div class="create-io">
    <span class="text-sm text-ems-gray500 mb-1">{{
      t('group-management.search-by-group-name')
    }}</span>
    <a-input
      type="text"
      v-model:value="formState.groupName"
      :placeholder="t('group-management.search-by-group-name-placeholder')"
      class="ant-input-sm !mb-4 bg-ems-tag1"
      :maxlength="255"
      :show-count="true"
      :allow-clear="true"
    />

    <span class="text-sm text-ems-gray500 mb-1">{{
      t('group-management.search-by-priority')
    }}</span>

    <cds-select
      class="!mb-4"
      :placeholder="t('group-management.search-by-priority-placeholder')"
      :options="WARNING_LEVEL"
      v-model:value="formState.warningLvl"
      :allow-clear="true"
    >
    </cds-select>

    <span class="text-sm text-ems-gray500 mb-1">{{
      t('group-management.search-by-object-name-placeholder')
    }}</span>
    <a-input
      type="text"
      v-model:value="formState.ioName"
      :placeholder="t('group-management.search-by-object-name-placeholder')"
      class="ant-input-sm mb-6 bg-ems-tag1"
      :maxlength="255"
      :show-count="true"
      :allow-clear="true"
    />

    <div class="h-183px w-full relative mb-10">
      <UploadSearch v-model:value="formState.img"></UploadSearch>
    </div>

    <div class="flex items-center justify-center">
      <clip-button type="primary" @click="emits('onSearchAdvanced')">{{
        $t('common.search')
      }}</clip-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchObject',
};
</script>
<script setup>
import { i18n } from '@/main';
import { defineEmits, reactive } from 'vue';
import UploadSearch from '@/components/upload-search/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { WARNING_LEVEL } from '@/config/Constant.js';
const { t } = i18n.global;

const props = defineProps({
  formState: {
    default: {},
  },
});

const formState = reactive(props.formState);

const emits = defineEmits(['onSearchAdvanced']);
</script>

<style lang="scss">
.create-io .cds-select .ant-select-selector .ant-select-selection-search {
  background-color: #373737 !important;
}
.create-io .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  border-radius: 8px;
  height: 38px;
  width: 100%;
}
.create-io .ant-select .ant-select-clear {
  right: 30px;
  top: 16px;
}
.create-io .ant-select .ant-select-selector {
  background-color: #373737 !important;
  border: none !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.create-io .ant-select .ant-select-selector input {
  height: 24px !important;
}
.create-io .ant-select .ant-select-selector .ant-select-selection-item {
  padding-right: 40px;
}
.create-io .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 55, 55, var(--tw-bg-opacity));
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.create-io .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
</style>
